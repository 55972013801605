<template>
  <div class="dropdown show" v-if="computedData.length">
    <button
      class="btn btn-light dropdown-toggle"
      role="button"
      id="dropdownMenuLink"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="fas fa-plus"></i> {{ $t("addCondition") }}
    </button>

    <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
      <span
        class="dropdown-item font-14"
        v-for="(source, index) in computedData"
        v-bind:key="source.code"
        v-on:click="selectCondition(index)"
      >
        <i v-bind:class="source.icon" />
        <span class="ml-2">{{ source.title }}</span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddCondition",
  data() {
    return {
      data: {
        source: this.source,
      },
    };
  },
  props: {
    source: {
      type: Array,
      default: () => {
        return null;
      },
    },
    conditions: {
      type: Array,
      default: () => {
        return null;
      },
    },
  },
  model: {
    prop: "source",
  },
  methods: {
    selectCondition(sourceIndex) {
      const copy = helper.clone(this.computedData[sourceIndex]);
      this.conditions.push(copy);
    },
    getConditionIndex(code) {
      return helper.arrayFind(this.conditions, "code", code);
    },
  },
  computed: {
    computedData: {
      get() {
        // return this.source;
        return this.data.source.filter((source) => {
          let index = this.getConditionIndex(source.code);
          if (index == -1) {
            return source;
          }
        });
      },
      set(value) {
        this.data.source = value;
      },
    },
  },
};
</script>
<style lang="scss">
.dropdown-item {
  cursor: pointer;
}
</style>
