<template>
  <div>
    <!-- Add Condition -->
    <AddCondition
      class="mb-3"
      v-bind:conditions.sync="data.conditions"
      v-model="data.source"
    />
    <!-- ./Add Condition -->

    <!-- Conditions -->
    <Content
      v-bind:conditions.sync="data.conditions"
      v-bind:operators="data.operators"
      v-on:on-remove="onRemove"
      v-on:on-edit="onEdit"
    />
    <!-- ./Conditions -->

    <!-- Condition Result -->
    <ConditionResult
      v-if="data.conditions && data.conditions.length"
      v-bind:result.sync="data.result"
    />
    <!-- ./Condition Result -->

    <!-- Modal -->
    <ConditionModal
      v-bind:conditions.sync="data.conditions"
      v-if="showModal && editIndex !== -1"
      v-bind:index="editIndex"
      v-on:close="onClose"
    />
    <!-- ./Modal -->
  </div>
</template>

<script>
import AddCondition from "./components/AddCondition/Index";
import Content from "./components/Content/Index";
import ConditionModal from "./components/ConditionModal/Index";
import ConditionResult from "./components/ConditionResult/Index";

export default {
  name: "WeCondition",
  data() {
    return {
      showModal: false,
      editIndex: -1,
      data: {
        conditions: this.conditions,
        operators: {
          equal: this.$t("equals"),
          many: this.$t("greater"),
          less: this.$t("smaller"),
          less_or_equal: this.$t("smallOrEqual"),
          many_or_equal: this.$t("greaterOrEqual"),
        },
        result: this.result,
        source: this.source,
      },
    };
  },
  components: {
    AddCondition,
    Content,
    ConditionModal,
    ConditionResult,
  },
  props: {
    conditions: {
      type: Array,
      default: () => {
        return null;
      },
    },
    result: {
      type: Object,
      default: () => {
        return {};
      },
    },
    source: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    onEdit(index) {
      this.showModal = true;
      this.editIndex = index;
    },
    onClose() {
      this.showModal = false;
      this.editIndex = -1;
    },
    onRemove(index) {
      this.conditions.splice(index, 1);
    },
  },
  watch: {
    "data.conditions": function (newVal, oldVal) {
      this.$emit("update:conditions", newVal);
      this.$emit("update:result", this.data.result);
    },
    deep: true,
  },
};
</script>
