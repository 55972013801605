<template>
  <ul v-if="showAttribute">
    <li v-if="firstText">
      <i>{{ firstText }}</i>
    </li>
    <li>
      <ConditionSelect
        v-if="attribute.type == 'select'"
        v-bind:condition-index="conditionIndex"
        v-bind:attribute-index="attributeIndex"
        v-bind:conditions.sync="conditions"
      />
      <ConditionOperator
        v-if="attribute.operators && attribute.operators.length"
        v-bind:attribute="attribute"
        v-bind:operators="operators"
      />
      <ConditionInput
        v-if="attribute.type == 'input'"
        v-bind:condition-index="conditionIndex"
        v-bind:attribute-index="attributeIndex"
        v-bind:conditions.sync="conditions"
      />
    </li>
    <li class="ml-3">{{ getLastText }}</li>
  </ul>
</template>
<script>
import ConditionSelect from "./components/ConditionSelect/Index";
import ConditionInput from "./components/ConditionInput/Index";
import ConditionOperator from "./components/ConditionOperator/Index";
export default {
  name: "ConditionAttribute",
  data() {
    return {
      firstText: null,
    };
  },
  components: {
    ConditionSelect,
    ConditionInput,
    ConditionOperator,
  },
  methods: {
    getActiveAttributes() {
      let result = this.condition.attributes.filter(
        (attr) => attr.active && attr.active === true
      );
      return result;
    },
    getActiveIndex() {
      const activeAttributes = this.getActiveAttributes();
      return helper.arrayFind(activeAttributes, "name", this.attribute.name);
    },
    setFirstText() {
      this.firstText = this.attribute.title;
    },
  },
  props: {
    conditionIndex: {
      default: 0,
    },
    attributeIndex: {
      default: 0,
    },
    conditions: {
      type: Array,
      default: () => {
        return null;
      },
    },
    operators: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  computed: {
    condition() {
      return this.conditions[this.conditionIndex];
    },
    attribute() {
      return this.condition.attributes[this.attributeIndex];
    },
    getLastText() {
      const attr = this.attribute;
      const cond = this.condition;
      const activeAttributes = this.getActiveAttributes();

      let attrCount = activeAttributes ? activeAttributes.length : 0;
      let currentIndex = this.getActiveIndex() + 1;
      let lastActive = currentIndex == attrCount;

      let result = "";
      if (attrCount >= 1) {
        if (attr.multiple) {
          if (attr.value && attr.value.length == 1) {
            result += " ise ";
          } else {
            result += " arasında ";
            if (lastActive) {
              // If Multiple Last Element
              if (attr.type == "input") {
                this.setFirstText();
                result += " olanlar ";
              } else {
                result += " bulunanlar ";
              }
              result += " için ";
            } else {
              result += " olan ve ";
            }
          }
        } else {
          // Single Not Last Element
          this.setFirstText();
          if (lastActive) {
            result += " olan için ";
          } else {
            result += " olan, ";
          }
        }
      } else {
        // Single Element
        if (attr.type == "input") {
          this.setFirstText();
          result += " olan ";
        }
        result += " için ";
      }

      // Last Phrase seperator
      if (
        // this.conditionIndex !== activeAttributes.length &&
        this.conditions.length > 1 &&
        currentIndex == attrCount
      ) {
        result += ", ";
      }

      // Fixed text
      if (attr.name == "total_price") {
        result = " ve üzeri ise, ";
      }
      if (cond.code == "product" && attr.name == "id") {
        result = " sepette mevcut ise, ";
      }
      if (attr.name == "sale") {
        result = "uygulanacaktır.";
      }

      return result;
    },
    showAttribute() {
      let prevAttr = this.condition.attributes[this.attributeIndex - 1];
      let result = false;

      switch (this.attribute.name) {
        case "model":
        case "city":
        case "district":
        case "neighborhood":
          if (
            this.attribute.active &&
            prevAttr.active &&
            prevAttr.value &&
            prevAttr.value.length == 1
          ) {
            result = true;
          } else {
            result = false;
          }
          break;

        default:
          result = this.attribute.active;
          break;
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
  display: contents;
  align-items: center;
}
</style>
