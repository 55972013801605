<template>
  <ul>
    <li class="condition-input" v-if="!show" v-on:click="toggleShow">
      {{ getSelectText }}
    </li>
    <li v-if="show">
      <v-select
        label="name"
        class="custom-v-select"
        @search:blur="toggleShow"
        v-bind:clearable="true"
        v-bind:placeholder="getOperatorName"
        v-model="attribute.operator"
        v-bind:options="getOperators"
        v-bind:close-on-select="false"
      ></v-select>
    </li>
  </ul>
</template>
<script>
export default {
  name: "ConditionSelect",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
  },
  props: {
    attribute: {
      type: Object,
      default: () => {},
    },
    operators: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  computed: {
    getSelectText() {
      let attr = this.attribute;

      let result = attr.title;

      if (attr.operator) {
        result = attr.operator.name;
      }

      return result;
    },
    getOperatorName() {
      return this.attribute.operator.name;
    },
    getOperators() {
      let attr = this.attribute;

      if (attr.operators && attr.operators.length) {
        let items = Object.entries(this.operators).map(([a, b]) => {
          const data = {
            id: a,
            name: b,
          };

          return data;
        });

        return items;
      } else {
        return [];
      }
    },
  },
};
</script>
