<template>
  <ul class="wecondition-list">
    <li
      class="row align-items-center no-gutters mb-3"
      v-for="(condition, conditionIndex) in conditions"
      v-bind:key="condition.code"
    >
      <ConditionButton
        v-bind:title="condition.title"
        v-bind:edit="condition.filterList"
        v-bind:condition-index="conditionIndex"
      />
      <ul
        class="col attribute-list"
        v-if="condition.attributes && condition.attributes.length"
      >
        <ConditionAttribute
          v-for="(attribute, i) in condition.attributes"
          v-bind:key="i"
          v-bind:attribute-index="i"
          v-bind:condition-index="conditionIndex"
          v-bind:conditions.sync="conditions"
          v-bind:operators="operators"
        />
      </ul>
    </li>
  </ul>
</template>
<script>
import ConditionButton from "./components/ConditionButton/Index";
import ConditionAttribute from "./components/ConditionAttribute/Index";
export default {
  name: "Content",
  components: {
    ConditionButton,
    ConditionAttribute,
  },
  props: {
    conditions: {
      type: Array,
      default: () => {
        return null;
      },
    },
    operators: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
};
</script>
<style lang="scss">
.wecondition-list {
  display: block;
  margin-bottom: 0.5rem;
  padding: 0;
  list-style-type: none;
}
.attribute-list {
  display: inline-block;
  list-style-type: none;
  margin-left: 0.7rem;
  li {
    margin-left: 0.5rem;
    display: inline-flex;
    align-items: center;
  }
}
.condition-select,
.condition-input {
  border: none;
  border-bottom: 1px solid #ffb300;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.45rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: 0.3s all ease-in-out;
}
.condition-select:hover,
.condition-input:hover {
  // box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  border-bottom-color: #5867dd;
}

.custom-v-select .vs__dropdown-toggle,
.custom-v-select .vs__dropdown-menu {
  min-width: 250px;
  max-height: 200px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ffb300;
  li {
    display: inherit !important;
    margin-left: 0;
  }
}
</style>
