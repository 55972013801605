<template>
  <div class="row align-items-center">
    <div class="col-auto">
      <i v-if="result.icon" v-bind:class="result.icon"></i>
      {{ result.title }}
    </div>
    <div class="col-auto">
      <div class="row">
        <!-- Condition Result -->
        <div class="col-auto">
          <v-select
            class="custom-v-select"
            label="name"
            v-model="result.value"
            v-bind:placeholder="result.title"
            v-bind:options="result.options"
            v-on:close="onResultChange"
            v-bind:clearable="false"
          >
          </v-select>
        </div>
        <!-- Condition Result -->
      </div>
    </div>
    <div class="col-auto">
      <v-select
        class="custom-v-select"
        v-bind:placeholder="$t('discountType')"
        label="name"
        @search:blur="clearAmount"
        v-bind:options="getDiscountTypes"
        v-model="result.discount.value"
        v-bind:clearable="false"
      >
        <template slot="no-options"> {{ $t("makeSearch") }} </template>
      </v-select>
    </div>
    <div
      class="col-auto"
      v-if="result.discount.value && result.discount.value.id !== 'free'"
    >
      <div class="row align-items-center">
        <span class="col-auto p-0">{{
          getDiscountPrepend || getDiscountAppend
        }}</span>
        <div class="col-auto pl-1">
          <input
            type="text"
            class="condition-input"
            v-model="result.discount.amount"
            v-bind:placeholder="$t('discountCount')"
          />
        </div>
      </div>
    </div>
    <div class="col-auto">
      <span>olarak uygulanacaktır.</span>
    </div>
    <WeModal
      v-if="modalShow && result.value.id == 'promotion'"
      v-bind:title="getModalTitle"
      v-on:close="modalShow = false"
    >
      <div slot="body">
        <!-- Fixed Area -->
        <div v-if="result.value !== null">
          <WeProduct
            class="mb-3"
            v-bind:title="$t('products')"
            name="recommended"
            v-model="saleProducts"
          />
        </div>
        <!-- ./Fixed Area -->
      </div>
    </WeModal>
  </div>
</template>
<script>
export default {
  name: "ConditionResult",
  data() {
    return {
      modalShow: false,
    };
  },
  props: {
    result: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onResultChange() {
      if (this.result.value !== null) {
        this.modalShow = true;
        this.clearAmount();
      }
    },
    clearAmount() {
      this.result.discount.amount = 0;
    },
  },
  watch: {
    "result.value": function (newVal) {
      if (
        newVal &&
        newVal.id == "discount" &&
        this.result.discount.value.id == "free"
      ) {
        this.result.discount.value = null;
      }
    },
  },
  computed: {
    saleProducts: {
      get() {
        return this.result.discount.products;
      },
      set(value) {
        this.result.discount.products = value;
      },
    },
    getModalTitle() {
      return this.result.value ? this.result.value.name : null;
    },
    getDiscountTypes() {
      let selectedType = this.result.value;
      if (selectedType && selectedType.id == "discount") {
        // Remove Free from the list
        return this.result.discountTypes.slice(0, 2);
      } else {
        return this.result.discountTypes;
      }
    },
    getDiscountAmountPlaceholder() {
      return this.result.discount.id == "percent"
        ? this.$t("discountRate")
        : this.$t("discountAmount");
    },
    getDiscountPrepend() {
      return this.result.discount.value.id == "percent" ? "%" : false;
    },
    getDiscountAppend() {
      return this.result.discount.value.id == "price" ? "₺" : false;
    },
  },
};
</script>
