<template>
  <WeModal
    v-bind:icon="condition.icon"
    v-bind:title="getModalTitle"
    v-on:close="$emit('close')"
  >
    <div slot="body" class="row">
      <div
        class="col-6 mb-3"
        v-for="(attribute, i) in attributes"
        v-bind:key="attribute.name"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            v-bind:id="componentId + '-' + i"
            v-bind:checked="attribute.active"
            v-on:change="setActiveStatus(i, $event)"
          />
          <label
            class="custom-control-label"
            v-bind:for="componentId + '-' + i"
            >{{ attribute.title }}</label
          >
        </div>
      </div>
    </div>
  </WeModal>
</template>
<script>
export default {
  name: "ConditionModal",

  props: {
    index: {
      default: 0,
    },
    conditions: {
      type: Array,
      default: () => {
        return null;
      },
    },
  },
  methods: {
    setActiveStatus(index, e) {
      this.$set(this.attributes[index], "active", e.target.checked);
    },
  },
  computed: {
    componentId() {
      return "wecondition-attribute-" + helper.getRandomInteger(1, 999999);
    },
    condition() {
      return this.conditions[this.index];
    },
    attributes() {
      return this.condition.attributes;
    },
    getModalTitle() {
      return this.$t("filtersOf", { title: this.condition.title });
    },
  },
};
</script>
<style lang="scss">
.custom-control-label {
  user-select: none;
}
</style>
