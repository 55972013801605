<template>
  <div class="dropdown">
    <button
      class="btn btn-light"
      type="button"
      v-bind:id="componentId + '-dropdown'"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ title }}
    </button>
    <div
      class="dropdown-menu"
      v-bind:aria-labelledby="componentId + '-dropdown'"
    >
      <a class="dropdown-item" v-if="edit" v-on:click="onEdit">
        <span> <i class="fas fa-pen fa-sm"></i> {{ $t("edit") }} </span>
      </a>
      <a class="dropdown-item" v-if="remove" v-on:click="onRemove">
        <span> <i class="fas fa-trash fa-sm"></i> {{ $t("remove") }} </span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConditionButton",
  props: {
    conditionIndex: {
      default: 0,
    },
    title: {
      default: null,
    },
    remove: {
      default: true,
    },
    edit: {
      default: true,
    },
  },
  methods: {
    onEdit() {
      this.$parent.$emit("on-edit", this.conditionIndex);
    },
    onRemove() {
      this.$swal({
        title: this.$t("willBeDeletedDoYouConfirm", { title: this.title }),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.$parent.$emit("on-remove", this.conditionIndex);
        }
      });
    },
  },
  computed: {
    componentId() {
      return "weconditionbutton-" + helper.getRandomInteger(1, 999999);
    },
  },
};
</script>
<style lang="scss" scoped>
.condition-btn {
  font-weight: bold;
  cursor: default !important;
  transition: 0.4s all;
}
.condition-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.btn-place {
  position: absolute;
  top: 0;
  right: 0;
}
.btn-remove,
.btn-edit {
  padding: 5px;
  display: none;
  transition: 0.4s all;
  cursor: pointer;
}
.btn-remove:hover {
  color: #e3342f;
}
.btn-edit:hover {
  color: #3490dc;
}
.condition-btn:hover .btn-remove,
.condition-btn:hover .btn-edit {
  display: block;
}
</style>
