<template>
  <ul>
    <li class="condition-input" v-if="!show" v-on:click="toggleShow">
      {{ getSelectText }}
    </li>
    <li v-if="show">
      <v-select
        v-if="condition.code !== 'product'"
        label="name"
        class="custom-v-select"
        @search:blur="toggleShow"
        v-on:search="fetchOptions"
        v-bind:clearable="true"
        v-bind:multiple="attribute.multiple"
        v-bind:placeholder="attribute.title"
        v-model="attribute.value"
        v-bind:options="attribute.options"
        v-bind:close-on-select="false"
      >
        <template slot="no-options">
          <span v-if="attribute.search">
            {{ $t("pleaseMakeSearch") }}
          </span>
          <span v-else>
            {{ $t("noResultsToShow") }}
          </span>
        </template>
      </v-select>
      <WeModal
        v-else
        icon="fas fa-cube"
        v-bind:title="$t('productSelection')"
        v-on:close="toggleShow"
      >
        <div slot="body">
          <WeProduct
            class="mb-3"
            v-bind:title="$t('products')"
            name="recommended"
            v-model="attribute.value"
          />
        </div>
      </WeModal>
    </li>
  </ul>
</template>
<script>
export default {
  name: "ConditionSelect",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    getOptions() {
      let attr = this.attribute;

      if (
        !attr.search &&
        !attr.options.length &&
        attr.route &&
        attr.route.length
      ) {
        let data = {
          options: attr.options,
          route: attr.route,
        };

        switch (attr.name) {
          case "model":
          case "city":
          case "district":
          case "neighborhood":
            const prevAttrValue =
              this.condition.attributes[this.attributeIndex - 1].value[0].id;

            data.route = attr.route.replaceAll("{id}", prevAttrValue);
            break;
        }

        this.$parent.$parent.$parent.$emit("get-options", data);
      } else {
        return attr.options;
      }
    },
    fetchOptions(e) {
      if (this.attribute.search) {
        // Set Condition name like Category insted of id
        this.attribute.condition_code = this.condition.code;

        if (e && e.length >= 3) {
          const data = {
            attr: this.attribute,
            search_route: this.attribute.route,
            text: e,
          };
          this.$parent.$parent.$parent.$emit("search-options", data);
        }
      }
    },
  },
  props: {
    conditionIndex: {
      default: 0,
    },
    attributeIndex: {
      default: 0,
    },
    conditions: {
      type: Array,
      default: () => {
        return null;
      },
    },
  },
  computed: {
    condition() {
      return this.conditions[this.conditionIndex];
    },
    attribute() {
      return this.condition.attributes[this.attributeIndex];
    },
    getSelectText() {
      let attr = this.attribute;

      let result = this.$t("selected") + " " + attr.title;

      if (!attr.multiple && attr.value) {
        result = attr.value.name;
      }

      // Get Single Name as selected text
      if (attr.name == "id" || attr.name == "model") {
        if (attr.value && attr.value.length == 1) {
          result = attr.value[0].name;
        }
      }
      return result;
    },
  },
  created() {
    this.getOptions();
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
  display: flex;
  align-items: center;
}
</style>
