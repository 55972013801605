<template>
  <ul>
    <li>
      <input
        type="text"
        class="condition-input"
        v-model="attribute.value"
        v-bind:placeholder="attribute.title"
      />
    </li>
  </ul>
</template>
<script>
export default {
  name: "ConditionInput",
  props: {
    conditionIndex: {
      default: 0,
    },
    attributeIndex: {
      default: 0,
    },
    conditions: {
      type: Array,
      default: () => {
        return null;
      },
    },
  },
  computed: {
    condition() {
      return this.conditions[this.conditionIndex];
    },
    attribute() {
      return this.condition.attributes[this.attributeIndex];
    },
  },
};
</script>
